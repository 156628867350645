.carItem {
  border: 1px solid #7c8a9736;
  padding: 20px;
  border-radius: 30px;
  border-left: 5px solid rgb(176, 190, 250);
  box-shadow: 1px 3px 10px rgb(234, 234, 234);
}

.car__item-info {
  font-size: 12px;
}
.car__item-info span i {
  color: #f9a826;
  font-size: 12px;
}

.carItem__btn {
  border-radius: 0;
  background: #000d6b;
  border: none;
  outline: none;
}

.carItem__btn-details {
  background: #f9a826;
  border-radius: 0px 4px 4px 0px;
}
.carItem__btn-rent:hover {
  background: #000d6b;
}
.carItem__btn-details:hover {
  background: #f9a826;
}

.carItem__btn-rent {
  border-radius: 4px 0px 0px 4px;
  font-size: 16px;
}

.car__item-content h4,
.car__item-content h6 {
  font-size: 14px;
}

.car__item-content h6 {
  font-weight: 600;
}
