.become__driver {
  background: linear-gradient(rgb(0, 13, 107, 0.9), rgb(0, 13, 107, 0.9)),
    url('../assets/img/drive.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 80px 0;
}

.become__driver button {
  background: #fff;
  color: #000d6b;
  font-weight: 600;
}

.become__driver h2 {
  color: #fff;
  font-size: 42px;
  line-height: 65px;
}

@media only screen and (max-width: 1199px) {
  .become__driver__title {
    font-size: 2rem !important;
    line-height: 3.5rem !important;
  }
}

@media only screen and (max-width: 991px) {
  .become__driver__title {
    font-size: 1.5rem !important;
    line-height: 3rem !important;
  }
}

@media only screen and (max-width: 767px) {
  .become__driver {
    padding: 50px 0;
  }
  .become__driver__title {
    font-size: 1.2rem !important;
    line-height: 2rem !important;
  }
  .become__driver__btn {
    font-size: 0.9rem;
  }
}

@media only screen and (max-width: 575px) {
  .become__driver {
    padding: 40px 50px;
    text-align: center;
  }
  .become__driver__img {
    display: none;
  }
  .become__driver__title {
    font-size: 1.5rem !important;
    line-height: 2rem !important;
    text-align: center;
  }
  .become__driver__btn {
    font-size: 0.6rem;
  }
}
