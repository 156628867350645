@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-color: #fcfcfc;
  font-family: 'sans-serif', lato;
  font-size: 15px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: #000d6b;
  font-weight: 600;
  font-size: 30px;
}
h3,
h4 {
  font-size: 25px;
}
h5,
h6 {
  font-size: 20px;
}
a {
  text-decoration: none;
  color: unset;
}

a:hover {
  color: unset;
}

ul {
  padding: 0;
  margin: 0;
  list-style: none;
}

section {
  padding: 50px 0;
}

.section__title {
  font-size: 35px;
  color: #000d6b;
  font-weight: 600;
}

.section__subtitle {
  font-size: 20px;
  color: #f9a826;
  font-weight: 600;
}

.section__description {
  font-size: 14px;
  color: #7c8a97;
  line-height: 30px;
}

.btn {
  padding: 8px 15px;
  border: none;
  outline: none;
  background: #000d6b;
  color: #fff;
  cursor: pointer;
  border-radius: 5px;
}

.btn:hover {
  background: #000d6b;
  color: #fff;
}

@media only screen and (max-width: 767px) {
  .section__title {
    font-size: 30px;
  }

  .section__subtitle {
    font-size: 14px;
  }

  .section__description {
    font-size: 12px;
  }

  section {
    padding: 30px 0;
  }
}
