/*** 

====================================================================
		Testimonial Section
====================================================================

***/
.large-container {
  position: static;
  max-width: 1580px;
  padding: 0px 30px;
  margin: 0 auto;
}

.testimonial-section {
  position: relative;
  padding-top: 80px;
  padding-bottom: 210px;
  overflow: hidden;
}
.testimonial-section:before {
  position: absolute;
  left: -200px;
  top: 22%;
  background-image: url(https://bizsupport.com.ng/images/icons/ring-circle.png);
  background-repeat: no-repeat;
  background-position: center;
  width: 701px;
  height: 756px;
  content: "";
  -webkit-animation: fa-spin 25s infinite alternate;
  animation: fa-spin 25s infinite alternate;
}
.testimonial-section .sec-title {
  position: relative;
  margin-bottom: 115px;
}
.testimonial-section .sec-title .title {
  margin-bottom: 20px;
}
.testimonial-section .testimonial-carousel {
  position: relative;
  max-width: 1100px;
  margin: 0 -50px;
}

.testimonial-block {
  position: relative;
  padding: 50px;
}
.testimonial-block .inner-box {
  padding: 80px 105px;
  background-color: #ffffff;
  box-shadow: 0 0 50px rgba(226, 222, 232, 0.75);
}
.testimonial-block .text {
  position: relative;
  display: block;
  font-size: 18px;
  line-height: 32px;
  color: #232431;
  font-weight: 400;
  margin-bottom: 50px;
  font-family: "Muli", sans-serif;
}
.testimonial-block .info-box {
  position: relative;
  padding-left: 115px;
  padding-top: 10px;
}
.testimonial-block .info-box .thumb {
  position: absolute;
  left: 0;
  top: 0;
  height: 82px;
  width: 82px;
}
.testimonial-block .info-box .thumb img {
  border: 6px solid #e5e6fa;
  border-radius: 50%;
  overflow: hidden;
  display: block;
  width: 100%;
  box-shadow: 0 45px 45px rgba(147, 147, 147, 0.35);
}
.testimonial-block .info-box .name {
  position: relative;
  display: block;
  font-size: 21px;
  line-height: 1.2em;
  color: #2c2f4d;
  font-weight: 700;
  margin-bottom: 10px;
  font-family: "Niramit", sans-serif;
}
.testimonial-block .info-box .designation {
  position: relative;
  display: block;
  font-size: 16px;
  line-height: 24px;
  color: #5361f7;
  font-weight: 400;
  font-family: "Muli", sans-serif;
}

.thumb-layer{
  opacity: 0.1;
}

.testimonial-carousel .owl-nav {
  position: absolute;
  right: 75px;
  bottom: 70px;
}
.testimonial-carousel .owl-next, .testimonial-carousel .owl-prev {
  position: relative;
  display: inline-block;
  height: 75px;
  width: 75px;
  line-height: 75px;
  text-align: center;
  border-radius: 50%;
  background-color: #ffffff;
  transition: all 300ms ease;
}
.testimonial-carousel .owl-next:hover, .testimonial-carousel .owl-prev:hover {
  background-color: #000d6b;
  box-shadow: 0 24px 24px rgba(187, 187, 187, 0.75);
}

.arrow-right, .arrow-left {
  position: relative;
  display: inline-block;
  height: 9px;
  width: 43px;
  background-image: url(https://bizsupport.com.ng/images/icons/arrow-left-2.png);
  background-repeat: no-repeat;
  background-position: center;
}

.arrow-right {
  background-image: url(https://bizsupport.com.ng/images/icons/arrow-right-2.png);
}

.testimonial-section .thumb-layer {
  position: absolute;
  right: 30px;
  top: 120px;
}
.testimonial-section .thumb-layer .image {
  position: relative;
  margin-right: 0;
}
.testimonial-section .thumb-layer .image img {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

/* Styles for screens smaller than 1080px */
@media screen and (max-width: 1080px) {
  .large-container {
    max-width: 100%;
    padding: 0 20px;
  }

  .testimonial-section {
    padding-top: 60px;
    padding-bottom: 180px;
  }

  .testimonial-section:before {
    left: -150px;
    width: 550px;
    height: 600px;
  }

  .testimonial-section .sec-title {
    margin-bottom: 80px;
  }

  .testimonial-block {
    padding: 40px;
  }

  .testimonial-block .inner-box {
    padding: 60px 80px;
  }

  .testimonial-block .text {
    font-size: 16px;
    line-height: 28px;
    margin-bottom: 40px;
  }

  .testimonial-block .info-box {
    padding-left: 90px;
  }

  .testimonial-block .info-box .thumb {
    height: 70px;
    width: 70px;
  }

  .testimonial-block .info-box .name {
    font-size: 18px;
  }

  .testimonial-block .info-box .designation {
    font-size: 14px;
  }

  .testimonial-carousel .owl-nav {
    right: 50px;
    bottom: 50px;
  }

  .testimonial-carousel .owl-next,
  .testimonial-carousel .owl-prev {
    height: 60px;
    width: 60px;
    line-height: 60px;
  }

  .arrow-right, .arrow-left {
    height: 7px;
    width: 35px;
  }
}

/* Styles for screens smaller than 768px (tablets and smaller devices) */
@media screen and (max-width: 768px) {
  .testimonial-section {
    padding-top: 50px;
    padding-bottom: 150px;
  }

  .testimonial-section:before {
    left: -100px;
    width: 450px;
    height: 500px;
  }

  .testimonial-block {
    padding: 30px;
  }

  .testimonial-block .inner-box {
    padding: 40px 60px;
  }

  .testimonial-block .text {
    font-size: 14px;
    line-height: 24px;
    margin-bottom: 30px;
  }

  .testimonial-block .info-box {
    padding-left: 70px;
  }

  .testimonial-block .info-box .thumb {
    height: 60px;
    width: 60px;
  }

  .testimonial-block .info-box .name {
    font-size: 16px;
  }

  .testimonial-block .info-box .designation {
    font-size: 12px;
  }

  .testimonial-carousel .owl-nav {
    right: 30px;
    bottom: 30px;
  }

  .testimonial-carousel .owl-next,
  .testimonial-carousel .owl-prev {
    height: 50px;
    width: 50px;
    line-height: 50px;
  }

  .arrow-right, .arrow-left {
    height: 6px;
    width: 30px;
  }
}

/* Styles for screens smaller than 480px (mobile devices) */
@media screen and (max-width: 480px) {
  .testimonial-section {
    padding-top: 40px;
    padding-bottom: 100px;
  }

  .testimonial-section:before {
    left: -80px;
    width: 350px;
    height: 400px;
  }

  .testimonial-block {
    padding: 20px;
  }

  .testimonial-block .inner-box {
    padding: 30px 40px;
  }

  .testimonial-block .text {
    font-size: 12px;
    line-height: 20px;
    margin-bottom: 20px;
  }

  .testimonial-block .info-box {
    padding-left: 60px;
  }

  .testimonial-block .info-box .thumb {
    height: 50px;
    width: 50px;
  }

  .testimonial-block .info-box .name {
    font-size: 14px;
  }

  .testimonial-block .info-box .designation {
    font-size: 11px;
  }

  .testimonial-carousel .owl-nav {
    right: 20px;
    bottom: 20px;
  }

  .testimonial-carousel .owl-next,
  .testimonial-carousel .owl-prev {
    height: 40px;
    width: 40px;
    line-height: 40px;
  }

  .arrow-right, .arrow-left {
    height: 5px;
    width: 25px;
  }
}
