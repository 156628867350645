
.service__box{
  border-radius: 20px;
  box-shadow: 1px 3px 10px rgb(246, 246, 246);
  column-gap: 2rem;
  background-color: white;
  border-top: 3px solid rgb(187, 197, 220);
  transition: ease-in-out 1s;
}
.service__box:hover{
  box-shadow: 1px 3px 10px rgb(223, 223, 223);
}

.service-box-content{
  padding: 20px;
}

.service-box-content h6{
 font-size: 12px;
}

.service__box p{
  font-size: 14px;
}

.service__box span i {
  font-size: 2.5rem;
  color: #f9a826;
}

.service__box h6:hover {
  color: #f9a826;
}

.service__box h6 {
  transition: 0.3s ease-in;
}

@media only screen and (max-width: 767px) {
  .service__box h6 {
    font-size: 1.1rem;
  }
}
