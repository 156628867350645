.blog__item {
  border: 1px solid #ffffff2e;
  font-size: 12px;
}

.author__name{
  font-size:12px;
}

.blog-content:hover{
  box-shadow: 1px 3px 10px rgb(234, 234, 234);
}

.read__more {
  color: #f9a826;
  font-weight: 600;
  font-size: 0.8rem;
}
.blog__time {
  border-top: 1px solid #7c8a972e;
}

.blog__time span i {
  color: #f9a826;
}

.blog__info h6{
  font-size: 14px;
}

.author__name {
  color: #141839;
  font-weight: 600;
}

.author__name i {
  font-weight: 500;
}

@media only screen and (max-width: 991px) {
  .blog__time {
    margin-top: 0 !important;
  }
}

@media only screen and (max-width: 767px) {
  .blog__time {
    padding-top: 5px !important;
  }
  .blog__time span {
    font-size: 0.7rem !important;
  }
  .blog__info h6 {
    font-size: 1.1rem;
  }
  .blog__info p {
    font-size: 0.7rem;
    line-height: 1.5rem;
    margin-bottom: 10px;
  }
  .read__more {
    font-size: 0.7rem;
  }
}
